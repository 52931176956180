import { render, staticRenderFns } from "./Workplace.vue?vue&type=template&id=3cab3492&scoped=true&"
import script from "./Workplace.vue?vue&type=script&lang=js&"
export * from "./Workplace.vue?vue&type=script&lang=js&"
import style0 from "./Workplace.vue?vue&type=style&index=0&id=3cab3492&lang=scss&scoped=true&"
import style1 from "./Workplace.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cab3492",
  null
  
)

export default component.exports