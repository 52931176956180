import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    dutyTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/duty-type/active', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkplaces(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/workplace', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkplace(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/workplace/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addWorkplace(ctx, workplace) {
      return new Promise((resolve, reject) => {
        axios
          .post('/workplace', workplace)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateWorkplace(ctx, workplace) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/workplace/${workplace.id}`, workplace)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeWorkplace(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/workplace/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStates() {
      return new Promise((resolve, reject) => {
        axios
          .get('/address/states')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, { stateId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/address/cities?state_id=${stateId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
